<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/marine', 'marine')">Home</b-button>
            </li>
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/size-your-system', 'marine')">Size your system</b-button>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + require('../assets/img/banner/size-your-system.jpg') + ')',
        }"
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <h1 class="mt-4">FWG • Freshwater Generators</h1>
              <div class="divider"></div>
              <p class="lead">
                Size Your System
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="containerLarge">
      <div class="row mt-5">
        <div class="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
            <p class="lead text-center pb-lg-4">Answer the following questions to find the right product for your needs.</p>
            <form class="steps-widget">
              <ul class="steps-list">
                <li class="active current" id="step1" >
                  <div class="label"><b-button class="btn" @click="getFocus('step1',1)"><i></i></b-button> 
                    <h4>How much fresh water per day?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <div class="action">
                        <div class="input-group mb-3">
                          <input v-model="water" type="text" class="col-6 col-sm-3 col-lg-2 form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                          <div class="input-group-append">
                              <span class="input-group-text" id="inputGroup-sizing-default">m<sup>3</sup>/24h</span>
                          </div>
                        </div>
                        <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step2',2)">Next</b-button>
                      </div>
                    </div>
                  </div>
                </li>
                <li id="step2" v-show="conditionStep2">
                  <div class="label"><b-button class="btn" @click="getFocus('step2',2)"><i></i></b-button> 
                    <h4>What's your jacket hot water inlet temperature?</h4>
                  </div>
                  
                  <div class="desc">
                    <div class="wrap">
                      <div class="input-group mb-3">
                        <input v-model="temperature" type="text" class="col-6 col-sm-3 col-lg-2 form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                        <div class="input-group-append">
                            <span class="input-group-text" id="inputGroup-sizing-default">°C</span>
                        </div>
                      </div>
                      <div class="small d-block">Enter a value greater than 55°</div>
                      <div class="action">
                          <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step3',3)">Next</b-button>
                      </div>
                    </div>
                  </div>
                </li>
                <li id="step3" v-show="conditionStep3">
                  <div class="label"><b-button class="btn" @click="getFocus('step3',3)"><i></i></b-button> 
                    <h4>Do you want to taking feed water from:</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap"> 
                      <b-form-group>
                        <b-form-radio v-model="waterType" value="seawater">From the flow of seawater coolant (Easier to install)</b-form-radio>
                        <b-form-radio v-model="waterType" value="feedwater">Do you want to taking ejector and feed water from the condenser outlet or from an alternative source (Higher efficiency)</b-form-radio>
                      </b-form-group>
                      <div class="action text-center mt-5">
                        <a v-if="!flag" class="btn btn-lg btn-turquoise linkClick" @click="getResult">Size Your System</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="wrap text-center mt-5"> <!-- non spostare altrimenti non visibile -->
                <a v-if="flag" class="btn btn-lg btn-turquoise linkClick" @click="getResult">Size your System</a>
              </div>
            </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    step: 1,
    water: 0,
    temperature: null,
    waterType: null,
    flag: false
  }),

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    },

    getCategoryName(){
      return this.$store.getters.getCategoryName; //this.$route.params.category_name;
    },

    getCategoryId(){
      return this.$store.getters.getCategoryId; //this.$route.params.category_id;
    },

    conditionStep2(){
      return Number(this.water) >= 10 && Number(this.water) <= 60;
    },

    conditionStep3(){
      return this.temperature !== null && Number(this.water) >= 10 && Number(this.water) <= 60;
    },

  },
  
  methods: {
    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },

    getFocus(_stepname, stepnumber){
      console.log('arriva', _stepname, stepnumber, this.temperature);
      this.flag = false;

      if (stepnumber === 2 && (Number(this.water) < 10 || Number(this.water) > 60)){
        this.temperature = null;
        this.getFocus('step3', 3);        
      } else if (stepnumber === 3 && this.temperature === null){
        this.waterType = null;
        this.flag = true;
        this.insideStep(_stepname, stepnumber);    
      } else {
        this.insideStep(_stepname, stepnumber);
      }
      console.log('flag', this.flag); 
    },

    insideStep(_stepname, stepnumber){
      this.step = stepnumber;      
      this.resetSteps();
      const currentStep = document.getElementById(_stepname);
      currentStep.classList.add('active', 'current');
    },

    resetSteps(){
      const step1 = document.getElementById('step1');
      const step2 = document.getElementById('step2');
      const step3 = document.getElementById('step3');

      step1.classList.remove('active', 'current');
      step2.classList.remove('active', 'current');
      step3.classList.remove('active', 'current');
    },

    getResult(){
      /* C-Type: seawater 
      S-Type: takes its feed water from the condenser outlet or an alternative source */
      console.log('queste scelte', this.getCategoryId, this.water, this.temperature, this.waterType);
      this.$http.get('/api/v1/configuratore-fwg', {
        params: { 
          catId: this.getCategoryId,
          water: this.water,
          temperature: this.temperature,
          waterType: this.waterType
        }
      })
        .then((resp) => {
          console.log('[Result configurator]', this.getCategoryName, resp);
          /* eslint-disable no-underscore-dangle */
          if (resp.data._id) {
            const usage = {};
            usage.productId = resp.data._id;
            usage.product = resp.data.name + ' ' + resp.data.size;
            usage.category = this.getCategoryName;
            this.$http.post('/api/datausage/save', usage)
              .then((ris) => {
                this.$store.commit('setCurrentGlobal', 'marine');
                this.$store.commit('setCurrentPath', '/product/' + resp.data._id);

                this.$router.push({
                  name: 'Product',
                  params: {
                    id: resp.data._id,
                    categoryId: this.getCategoryId
                  }
                });
              })
              .catch((err) => {
                console.log('Error on save datausage', err);
              });
            
          } else this.$router.push('/size-your-system/notfound');
        })
        .catch((err) => {
          console.log('Error on configurator', err);
        });
    }

  }
};
</script>
